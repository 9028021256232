<script>
import {Option, Select} from "element-ui";
import {endpoints} from "../../../routes/endpoints";

export default {
  name: "ClassroomsSelect",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    value: {},
    initialValue: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
  },
  methods: {
    async remoteMethod(query) {
      if(query?.length >= 3) {
        try {
          this.loading = true
          const resp = await this.$api.post(endpoints.SELECT.classrooms, {query: query})
          this.options = resp?.data || []
        } catch (e) {
          this.options = [];
        } finally {
          this.loading = false
        }
      }
    },
  },
  data() {
    return {
      options: [],
      loading: false,
      selectKey: 1
    }
  },
  mounted() {
    if(this.initialValue?.id) {
      console.log('ci entro ???')
      this.options = [
        {value: this.initialValue.id, name: this.initialValue.code}
      ]
      this.selectKey++;
      console.log(this.options, this.value)
    }


  }
}
</script>

<template>
    <base-input class="mb-0 flex-grow-1">
      <el-select
        :disabled="disabled"
        class="select-default text-uppercase"
        filterable
        clearable
        remote
        reserve-keyword
        default-first-option
        :placeholder="$t('fields.choose')"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :remote-method="remoteMethod"
        :loading="loading"
        :key="`select-${selectKey}`"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item.value">
        </el-option>
      </el-select>
    </base-input>

</template>

<style scoped>

</style>
