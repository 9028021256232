<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.accounting_data') }}</div>
              </div>
            </template>
            <octo-header-button
                :title="registry.surname + ' ' + registry.name"
                :buttons="paymentButtons"
                @onOpenOctoEmail="openOctoEmail('payment')"
            />
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <deal-accounting-card
                    :deal="deal"
                    :key="`deal-accounting-card-${updateAfterMountedKey}`"
                    @onUpdateDealOnly="updateDealOnly"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <commissions-recap :commissions="commissions"/>
                </div>
                <div class="col-12">
                  <deal-holder-data :deal="deal" :key="`deal-holder-${updateAfterMountedKey}`"/>
                </div>
                <div class="col-12">
                  <deal-payment-tab :deal="deal" :key="`payments-tab-${updateAfterMountedKey}`"/>
                </div>
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
                :title="registry.surname + ' ' + registry.name"
                :buttons="[{label: 'open', onClick: 'onOpenRegistry'}]"
                @onOpenRegistry="$router.push({name: 'registries.show', params: {id: registry.id}})"
            />
            <registry-tab-pane
                :registry="registry"
                :key="`registry-tab-pane-${updateAfterMountedKey}`"
                v-on:onSetEmails="handleOnSetEmails"
            />
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.deal') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-12">
                <octo-header-button
                    :buttons="[{
                            label: 'send_email',
                            onClick: 'onOpenOctoEmail'
                          }]"
                    :title="registry.surname + ' ' + registry.name"
                    @onOpenOctoEmail="openOctoEmail('deal')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <deal-card
                    :deal="deal"
                    :available-transitions="availableTransitions"
                    :key="`deal-card-${updateAfterMountedKey}`"
                    @onUpdateDealData="updateDealData"
                />
              </div>
              <div class="col-md-5">
                <deal-status-container
                    :deal="deal"
                    :key="`dealStatusContainer-${updateAfterMountedKey}`"
                />
                <deal-history :deal="deal" :key="`dealHistory-${updateAfterMountedKey}`"/>
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.documents') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <deal-documents-updated
              :read-only="isBalanced"
              :deal="deal"
              :key="`deal-documents-${updateAfterMountedKey}`"
            />
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.courses') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <div class="row">
              <div class="col-12">
                <deal-contract-data-card
                  :deal="deal"
                  :key="`deal-datum-form-${updateAfterMountedKey}`"
                  @onUpdateDealOnly="updateDealOnly"
                />
              </div>
              <div class="col-12 col-md-6">
                <deal-courses-card
                  :deal="deal"
                  :key="`deal-courses-card-${updateAfterMountedKey}`"
                  @onUpdateDealOnly="updateDealOnly"
                />
              </div>
              <div class="col-12 col-md-6">
                <octo-notes
                  entity="deal"
                  type="course"
                  :entity-id="deal.id"
                  :notes="deal.courseNotes"
                  :available-tags="['course']"
                  :key="`course-notes-${updateAfterMountedKey}`"
                />
              </div>
            </div>

          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('didactics.students') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="[{
                            label: 'send_email',
                            onClick: 'onOpenOctoEmail'
                          }]"
              :title="registry.surname + ' ' + registry.name"
              @onOpenOctoEmail="openOctoEmail('student')"
            />
            <registry-students :registry="deal.registry" :key="`registry-students-${updateAfterMountedKey}`"/>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.notes') }}</div>
              </div>
            </template>
            <octo-header-button
                :title="registry.surname + ' ' + registry.name"
            />
            <octo-notes
                read-only
                entity="deal"
                :entity-id="deal.id"
                :notes="deal.allNotes"
                :key="`all-notes-${updateAfterMountedKey}`"
            />
          </tab-pane>

        </tabs>
      </div>
    </div>
    <octo-email
        :target-id="deal.id"
        :target-type="emailTargetType.DEAL"
        :emails="registry.emails"
        :allowed-email-templates="allowedEmailTemplates"
        :show-modal="showEmailModal"
        :key="`email-modal-${modalKey}`"
        @onClose="showEmailModal = false"
        @emailSent="updateAfterMountedKey++; showEmailModal = false"
    />
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Deal from "@/models/deal";
import Registry from "@/models/registry";
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import DealSummary from "@/pages/Deals/components/DealSummary";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealSummaryGeneral from "@/pages/Deals/components/DealSummaryGeneral";
import DealCoursePicker from "@/pages/Deals/components/DealCoursePicker";
import DealContractDataCard from "@/pages/Deals/components/DealContractDataCard";
import {permissionService} from "@/util/permission-service";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import DealConfirmedDate from "@/pages/Deals/components/DealConfirmedDate";
import DealExtraInfo from "@/models/dealExtraInfo";
import LeadHistory from "@/pages/CallCenter/OperatorComponents/LeadHistory";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import DealDateArchiveScheduler from "@/components/DealDateScheduler/DealDateArchiveScheduler";
import {mapGetters} from "vuex";
import LeadTabPane from "@/components/TabPanels/LeadTabPane";
import DealPaymentTab from "@/pages/Deals/components/DealPaymentTab";
import DealCard from "@/pages/Deals/components/DealCard";
import DealCoursesCard from "@/pages/Deals/components/DealCoursesCard";
import DealDocumentsUpdated from "@/pages/Deals/components/DealDocumentsUpdated";
import Modal from "@/components/Modal";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import DealStudents from "@/pages/Deals/components/DealStudents";
import OctoEmail, {EMAIL_TEMPLATE_NAME, EMAIL_TARGET_TYPE} from "@/components/octo-email/OctoEmail";
import DealAccountingCard from "@/pages/Deals/components/DealAccountingCard";
import DealHistory from "@/pages/Deals/components/DealHistory";
import DealHolderData from "@/pages/Deals/components/DealHolderData.vue";
import OctoNotes from "@/components/OctoNotes.vue";
import CommissionsRecap from "@/pages/PostSelling/components/CommissionsRecap.vue";
import RegistryStudents from "@/pages/Students/components/RegistryStudents.vue";

export default {
  name: "PostSellingPage",
  components: {
    RegistryStudents,
    CommissionsRecap,
    OctoNotes,
    DealHolderData,
    DealHistory,
    DealAccountingCard,
    OctoEmail,
    DealStudents,
    ListSelectorComponent,
    Modal,
    DealDocumentsUpdated,
    DealCoursesCard,
    DealCard,
    DealPaymentTab,
    LeadTabPane,
    DealDateArchiveScheduler,
    RegistryTabPane,
    DealConfirmedDate,
    OctoHeaderButton,
    DealContractDataCard,
    DealCoursePicker,
    DealSummaryGeneral,
    DealSummary,
    DealStatusContainer,
    RegistryPersonalData,
    OctoIcon,
    Tabs,
    TabPane,
    LeadHistory
  },
  data() {
    return {
      endpoints: endpoints,
      deal: this.$_.cloneDeep(Deal),
      registry: this.$_.cloneDeep(Registry),
      updateAfterMountedKey: 1,
      schedulerKey: 1,
      dealStatuses: DealStatuses,
      isBalanced: false,
      permissionService: permissionService,
      availableTransitions: [],
      showEmailModal: false,
      modalKey: 0,
      paymentButtons: [{label: 'send_email', onClick: 'onOpenOctoEmail'}],
      allowedEmailTemplates: [],
      emailTargetType: EMAIL_TARGET_TYPE,
      commissions: []
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.POST_SELLING_SHOW.replace('{id}', this.$route.params.id))
        .then((resp) => {
          this.deal = resp?.data?.data || this.$_.cloneDeep(Deal);
          if (!this.deal.dealExtraInfo) {
            this.deal.dealExtraInfo = this.$_.cloneDeep(DealExtraInfo);
          }
          this.registry = this.deal?.registry || this.$_.cloneDeep(Registry);
          this.isBalanced = resp?.data?.isBalanced || false;
          this.availableTransitions = resp?.data?.availableTransitions || [];
          this.commissions = resp?.data?.commissions || [];
          this.updateAfterMountedKey++;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
          this.$router.push({name: "deals.archive"});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
  },
  computed: {
    ...mapGetters({
      sales: 'common/sales',
      operators: 'common/operators',
      users: 'common/users',
      currentUser: 'auth/currentUser',
    }),

  },
  methods: {
    updateDealData(dealData) {
      this.deal = dealData?.data || this.$_.cloneDeep(Deal);
      this.availableTransitions = dealData?.availableTransitions || [];
      this.updateAfterMountedKey++;
    },

    updateDealOnly(deal) {
      this.deal = deal || this.$_.cloneDeep(Deal);
      this.updateAfterMountedKey++;
    },

    openOctoEmail(tab) {
      this.allowedEmailTemplates = [EMAIL_TEMPLATE_NAME.GENERIC];

      switch (tab) {
        case 'deal':
          this.allowedEmailTemplates.push(EMAIL_TEMPLATE_NAME.DEAL_DATES);
          break;
        case 'student':
          this.allowedEmailTemplates.push(
            EMAIL_TEMPLATE_NAME.COURSE_START,
            EMAIL_TEMPLATE_NAME.COURSE_START_FIRST_INSTALLMENT
          );
          break;
        case 'payment':
          this.allowedEmailTemplates.push(
              EMAIL_TEMPLATE_NAME.INSTALLMENT_MEMORANDUM,
              EMAIL_TEMPLATE_NAME.ENROLLMENT_FEE_MEMORANDUM,
              EMAIL_TEMPLATE_NAME.INSTALLMENT_REMINDER,
              EMAIL_TEMPLATE_NAME.LAST_INSTALLMENT_REMINDER,
              EMAIL_TEMPLATE_NAME.PATH_BLOCK,
              EMAIL_TEMPLATE_NAME.NO_REGULAR_WITHDRAWAL,
              EMAIL_TEMPLATE_NAME.NAME_ENROLLMENT_REMINDER,
              EMAIL_TEMPLATE_NAME.NOTICE_TO_LAWYER,
          );
          break;
      }

      this.modalKey++;
      this.showEmailModal = true;
    },

    handleOnSetEmails(emails){
      this.registry.emails = emails;
    }
  }
}
</script>

<style scoped>

</style>
