<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.summary') }}</div>
              </div>
            </template>
            <student-waiting-recap :key="`recap-${studentDataKey}`" />
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('datatable.schedule_preferences') }}</div>
              </div>
            </template>
            <student-schedule-preferences-tab/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('page.classrooms_pending') }}</div>
              </div>
            </template>
            <student-waiting-classroom/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('page.classrooms_on_going') }}</div>
              </div>
            </template>
            <student-waiting-classroom-on-going/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="[ { onClick: 'onOpenRegistry', label: 'open_registry' } ]"
              :title="`${registry.name} ${registry.surname}`"
              @onOpenRegistry="$router.push({name: 'registries.show', params: {id: student.registry.id}})"
            />
            <registry-tab-pane :registry="student.registry" :key="`registry-tab-pane-${studentDataKey}`"/>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import StudentSchedulePreferencesTab from "@/pages/Students/components/StudentSchedulePreferencesTab";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import StudentWaitingClassroom from "@/pages/Students/components/StudentWaitingClassroom";
import {mapGetters, mapMutations} from "vuex";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import StudentPending from "@/store/current/resources/studentPending";
import Registry from "@/models/registry";
import StudentWaitingRecap from "@/pages/Students/components/StudentWaitingRecap";
import StudentWaitingClassroomOnGoing from "./components/StudentWaitingClassroomOnGoing";

export default {
  name: "ShowStudentWaitingPage",
  components: {
    StudentWaitingClassroomOnGoing,
    StudentWaitingRecap,
    RegistryTabPane,
    StudentWaitingClassroom,
    OctoHeaderButton,
    StudentSchedulePreferencesTab,
    Tabs,
    TabPane,
  },
  data() {
    return {
      student: this.$_.cloneDeep(StudentPending),
      studentDataKey: 1,
      classrooms: [],
      isCertificate: false,
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses'
    }),
    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    }
  },
  beforeMount() {

    this.$fullLoading.show();
    this.student = this.$_.cloneDeep(StudentPending);
    this.setStudentPending(this.student);
    this.$api.get(endpoints.STUDENT_SHOW_WAITING.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.student = {...this.student, ...resp?.data?.data}
        this.student.availableClassrooms = resp?.data?.meta?.availableClassrooms || [];
        this.student.availableOnGoingClassrooms = resp?.data?.meta?.availableOnGoingClassrooms || [];
        this.setStudentPending(this.student);
      })
      .catch((e) => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        // await this.$router.push({name: 'students.waiting.index'})
      })
      .finally(() => {
          this.$fullLoading.hide();
          this.studentDataKey++;
        }
      )


    // this.student.mainClassroom = this.$_.find(this.student.classrooms, (classroom) => {
    //   return classroom.type === 'main'
    // }) || this.$_.cloneDeep(Classroom);
    //
    // const indexClassroomEndPoint = new URLSearchParams({
    //   'course_id': this.student.course_id,
    //   'statuses': [
    //     ClassroomStatuses.pending.value,
    //     ClassroomStatuses.scheduled.value
    //   ]
    // });
    //
    // const respClassroom = await this.$api.get(endpoints.CLASSROOM_INDEX + '?' + indexClassroomEndPoint.toString());
    // this.classrooms = respClassroom?.data?.data || [];
    // const course = this.$_.find(this.courses, (course) => {
    //   return course.id === this.student.course_id
    // })
    //
    // this.isCertificate = course && course.type === 'certificate';
    // this.studentDataKey++;
    //   this.$fullLoading.hide();
    // } catch (e) {
    //   this.$fullLoading.hide();
    //   this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
    //   // await this.$router.push({name: 'students.waiting.index'})
    // }
  },
  methods: {
    ...mapMutations({
      setStudentPending: 'current/setStudentPending'
    }),
  }
}
</script>

<style scoped>

</style>
