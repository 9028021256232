import Registry from "@/models/registry";

const StudentPending = {
  id : null,
  course_id : null,
  prev_classroom_id : null,
  is_new : false,
  status : null,
  date_in : null,
  date_out : null,
  first_contact : null,
  second_contact : null,
  accounting_approval : false,
  registry: _.cloneDeep(Registry),
  studentSchedulePreferences: [],
  availableClassrooms: [],
  prevClassroom: null,
};

export default StudentPending
